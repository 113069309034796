import React, { useEffect, useState } from 'react';
import './form.scss';
import './input.scss';

export default function Input(props) {
  const {
    wrapperClassName = '',
    className = '',
    children,
    type = 'text',
    id,
    name,
    label,
    hasError,
    errorMessage,
    labelAfter,
    onChange = () => {},
    ...restProps
  } = props;

  const [ showError, setShowError ] = useState(hasError);

  const radioCheck = type === 'checkbox' || type === 'radio';
  const classes = ['form__input', ...className.split(' ')];
  const wrapperClasses = ['form__item', `form__item--${type}`, ...wrapperClassName.split(' ')];

  if (labelAfter) { wrapperClasses.push('form__item--label-after'); }

  const renderLabel = () => label ? (<label htmlFor={id}>{ label }</label>) : null;

  const onValueChange = (e) => {
    // const { value } = e.target;

    setShowError(!e.target.checkValidity());

    onChange(e);
  }

  useEffect(() => {
    if (hasError !== showError) {
      setShowError(hasError);
    }
  }, [hasError]);

  return (
    <div className={`${wrapperClasses.join(' ')}${ showError ? ' form__item--error' : '' }`}>
      { (!radioCheck && !labelAfter) ? renderLabel() : null }
      <input
        className={classes.join(' ')}
        id={id}
        name={name}
        type={type}
        onChange={onValueChange}
        {...restProps}
        // eslint-disable-next-line
        pattern={type === 'email' ? '^[a-zA-Z0-9._%+\\-]+@[a-zA-Z0-9.\\-]+\\.[a-zA-Z]{2,}$' : props.pattern } // remove + sign || default: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
        />
      { (radioCheck || labelAfter) ? renderLabel() : null }
      { showError && errorMessage ? (<p className="p--3 fc--negative mt--1 mb--0">{ errorMessage }</p>) : null }
      { children }
    </div>
  )
};
